
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String as PropType<string>,
      default: null,
    },
    invalid: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    invalidMessage: {
      type: String as PropType<string>,
      default: null,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
})
